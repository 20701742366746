<template>
  <!-- PC图表展示页面 -->
  <div class="echarss" v-loading="vloading">
    <!-- 头部筛选 -->
    <div class="header-filter">
      <!-- 左侧搜索 -->
      <div class="filter-left">
        <div class="leftSearchBox" v-show="inputModel.length > 0" v-for="(item, index) in inputModel" :key="index">
          <div v-if="item.moduleType || item.moduleType == 0"> 
            <!-- 根据moduleType判定搜索框 -->
            <el-input v-if="item.moduleType == 0 && item.ftype !== 1" :placeholder="item.showName" v-model="item.value"
              style="margin: 10px; width: 120px" size="mini" :type="item.ftype == 1 ? 'number' : 'text'"
              @keyup.enter.native="handleEnter" />
            <el-input v-if="item.ftype == 1 && item.moduleType == 0" type="number" v-model="item.value"
              :placeholder="item.showName" style="margin: 10px; width: 120px" size="mini" />
            <el-select v-else-if="item.moduleType == 1" v-model="item.filterDataValue" filterable
              :multiple='item.isWhereMulti' :collapse-tags="item.isWhereMulti" :placeholder="item.showName"
              style="margin: 10px; margin-right: 0; width: 160px" size="mini">
              <el-option v-for="(it,index) in item.filterDataList" :key="index" :label="it" :value="it" />
            </el-select>
            <el-date-picker v-if="item.moduleType == 3" style="margin: 10px; width: 200px" size="mini"
              v-model="item.betweenValue" type="daterange" align="right" value-format="yyyy-MM-dd" unlink-panels
              range-separator="至" :start-placeholder="item.showName+'开始'" :end-placeholder="item.showName+'结束'" />
            <el-date-picker v-if="item.moduleType == 2"  value-format="yyyy-MM-dd" v-model="item.value" style="margin: 10px; width: 140px"
              size="mini" type="date" :placeholder="'选择'+item.showName" />
          </div>
          <!-- 为了兼容原有数据 可能会出现moudleType没填的情况 则继续按照之前的逻辑判断 -->
          <div v-else-if="!item.moduleType && item.moduleType !== 0">
            <!-- 单独文本框 只能是ftype != 2&& -1,筛选类型为0，1 -->
            <el-date-picker v-if="(item.ftype == 2 && item.filterType == 2 )|| item.fname === 'y_month'" style="margin: 10px; width: 200px" size="mini"
              v-model="item.betweenValue" type="daterange" align="right" value-format="yyyy-MM-dd" unlink-panels
              range-separator="至" :start-placeholder="item.showName+'开始'" :end-placeholder="item.showName+'结束'" />
            <el-date-picker v-else-if="item.ftype == 2 && item.filterType != 2" v-model="item.value"
              style="margin: 10px; width: 140px" size="mini" type="date"  :placeholder="'选择'+item.showName" />

            <!-- <el-date-picker  v-if="item.ftype == 2 && item.filterType == 2" style="margin: 10px; width: 200px"
            size="mini" v-model="item.betweenValue" type="monthrange" range-separator="至" 
            start-placeholder="开始月份" end-placeholder="结束月份" /> -->

            <!--  数字框和选择大小 -->
            <el-select v-else-if="item.ftype == 1 && item.filterType == 3 " v-model="item.compareType"
              placeholder="比较类型" style="margin: 10px; margin-right: 0; width: 60px" size="mini">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-input v-else-if="item.ftype != 2 && item.ftype != 1" :placeholder="item.showName" v-model="item.value"
              style="margin: 10px; width: 120px" size="mini" @keyup.enter.native="handleEnter" />
          </div>
        </div>

        <el-button v-if="inputModel.length > 0" type="primary" style="margin: 10px" @click="handleSearch">查找</el-button>
        <el-button v-if="inputModel.length > 0" type="primary" style="margin: 10px" @click="processReset">重置</el-button>
      </div>
      <!-- 右侧切换图表 -->
      <div class="filter-right">
        <div class="buttonBox">
        <el-radio-group v-model="switchData" size="mini" style="margin: 10px 30px 10px 10px"
          @change="handleSwitchChange">
          <el-radio-button label="1">表格</el-radio-button>
          <el-radio-button label="2">图表</el-radio-button>
        </el-radio-group>
        <el-button v-if="fieldPropConfigs && fieldPropConfigs.length > 0 && switchData==1 && remarksShow != false"
          size="mini" type="primary" style="margin-left: auto;" @click="saveNotes">保存备注</el-button>
        <el-button v-if="fieldPropConfigs && fieldPropConfigs.length > 0 && switchData == 1" size="mini" type="primary"
          style="margin:10px 10px 10px 30px; padding-rinht: 10px" @click="inputClick">导出</el-button>
      </div>
        <!-- 下拉选择图表 -->
        <el-dropdown @command="selectChart">
          <el-button type="primary">选择对应图表<i class="el-icon-arrow-down el-icon--right"></i></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in allDataEchart" :key="index" :command="item">{{ item.catName
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="echars">
      <div v-if="switchData == 1" style="height: 100%;display: flex;flex-direction: column;overflow: auto;">
        <!-- 数据集界面 -->
        <div v-if="fieldPropConfigs && fieldPropConfigs.length > 0" :style="{position: 'relative',height:subTableName.length? '50%' : '100%'}" >
          <el-table stripe border :data="tableData" height="85%"  :summary-method="getSummaries"
            show-summary @sort-change="sortChange" @row-click="openDetails" :row-class-name="tableRowClassName"
            :cell-class-name="tableCellClassName" @cell-click="cellClick" ref="tableRef">
            <el-table-column label="序号" type="index" width="80" align="center" fixed="left">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :fixed="item.isFixed" v-for="(item, index) in fieldPropConfigs" :key="index" show-overflow-tooltip
              sortable="custom" :prop="item.fname" :label="item.showName" :width="item.colWidth ? item.colWidth : '150'"
              :align="item.ftype == '1' ? 'right' : 'left'" 
              :render-header="(createElement, { column, $index }) => renderSpecNameHeader(createElement, { column, $index }, item)">
              <template slot-scope="scope">
                <span v-if="scope.row.backgSpan" class="backgSpanvalue">{{ scope.row[item.fname] | decimalDigit(item,that) }}</span>
                <span v-else-if="scope.row.index === rowIndex && scope.column.index === columnIndex && scope.column.label == '备注'">
                  <el-input v-model="scope.row[item.fname]" type="textarea" placeholder="请输入备注" size="mini"
                    @blur="inputblur(scope.row)" />
                </span>
                <span v-else>{{ scope.row[item.fname]| decimalDigit( item,that ) }}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination style="margin-top: 20px" v-if="fieldPropConfigs && fieldPropConfigs.length > 0"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" background
            :current-page="tableDataParam.pageNum" :page-sizes="[20, 50, 100, 200]" :page-size="tableDataParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="tableDataParam.totalRecord">
          </el-pagination>
        </div>
        <!-- 子表 -->
        <div v-if="subTableName.length" style="height:50%" >
          <el-tabs @tab-click="handleClick" type="border-card" v-model="activeName" style="height:100%;">
            <el-tab-pane v-for="(item, index) in subTableName" :key="index" :label="item.dsName" :name="item.id"
              style="height: 100%;position: relative;">
              <el-table height="85%" border :data="subTableData" v-loading="loading" :summary-method="getSummariess"
                show-summary>
                <el-table-column label="序号" type="index" width="80" align="center" fixed="left"></el-table-column>
                <el-table-column :fixed="items.isFixed" v-for="(items, index) in subTableHeader" :key="index" :label="items.showName"
                  :prop="items.fname" show-overflow-tooltip>
                  <template slot-scope="scope">
                  <span >{{ scope.row[items.fname]| decimalDigit( item,that ) }}</span>
              </template>
                </el-table-column>
              </el-table>
              <el-pagination style="margin-top: 20px" v-if="subTableData && subTableData.length > 0"
                @size-change="handleSizeChangess" @current-change="handleCurrentChangess" background
                :current-page="subTableDataList.pageNum" :page-sizes="[20, 50, 100, 200]"
                :page-size="subTableDataList.pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="subTableDataList.totalRecord"></el-pagination>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="nodata" v-if="!fieldPropConfigs.length && !subTableName.length">暂无数据</div>
      </div>
      <!-- 所有图表界面 -->
      <div v-else-if="switchData == 2" class="echarsBoxd" :style="{ height: heightData }" ref="canvasBox">
        <div v-for="(item, index) in eChartsData" :key="index" class="chartsBox" :style="{
          width: item.webConfig.width + 'px', height: item.webConfig.height + 'px',
          top: item.webConfig.coordinateY + 'px', left: item.webConfig.coordinateX + 'px',
          maxWidth: '100%'
        }">
          <div class="searnBox">
            <el-button @click="chartsDBclick(item)">详情</el-button>
          </div>
          <div class="chartsSunBox" :id="item.webConfig.chartsId"></div>
        </div>
        <div class="nodata" v-if="!eChartsData.length">暂无数据</div>

      </div>
    </div>
    <el-drawer title="筛选" :visible.sync="dataDrawer" :modal="false" direction="rtl" :before-close="dataDrawerhandleClose"
      size="20%">
      <el-divider content-position="left">数据源筛选</el-divider>
      <Y-form :dataSourceCondition="dataSourceCondition" :chartsTypeed="chartsType" :dataSourceOption="dataSourceOption"
        :deleteDisabled="true" @dataSelectChange="dataSelectChange" @searchClick="echartsSearchClick" />
      <Y-searchPcForm :searchFormInformation="searchFormInformation" @searchClick="searchClick"></Y-searchPcForm>
    </el-drawer>
    <el-drawer title="图表数据" :visible.sync="drawerBottom" direction="btt" :modal="false" size="100%">
      <div class="echarsBox">
        <div class="echBox">
          <!-- 合计数据 -->
          <div class="searceBox">
            <div class="inpiutBox">
              <el-input size="mini" v-model="tableSearce.top" placeholder="记录数"></el-input>
            </div>
            <div class="inpiutBox">
              <el-select size="mini" v-model="tableSearce.orderBy" placeholder="排序字段">
                <el-option v-for="(item, idnex) in sortFieldoptions" :key="idnex" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="inpiutBox">
              <el-select size="mini" v-model="tableSearce.isAsc" placeholder="排序方式">
                <el-option v-for="(item, idnex) in sortordoptions" :key="idnex" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="buttonBox">
              <el-button type="primary" @click="tableClick">查询</el-button>
              <el-button type="primary" @click="resetDetail">重置</el-button>

            </div>
          </div>
          <el-table class="tableBox" ref="echarsTable" @sort-change="echarsSortChange"
            v-if="sumFieldPropConfigs && sumFieldPropConfigs.length" border :data="sumTableData"
            height="calc(100% - 110px)" stripe style="width: 100%">
            <el-table-column label="序号" type="index" width="80" align="center" fixed="left"></el-table-column>
            <el-table-column :fixed="item.isFixed" v-for="(item, index) in sumFieldPropConfigs" sortable="custom" :key="index"
              :prop="item.fname" :label="item.showName" show-overflow-tooltip
              :align="item.ftype == '1' ? 'right' : 'left'" 
              :width="item.colWidth ? item.colWidth : '150'">
              <template slot-scope="scope">
                {{ scope.row[item.fname]| decimalDigit( item,that ) }}
              </template>
            </el-table-column>
          </el-table>
          <div v-else class="tablespanBox">
            <span>没有数据，数据可能未配置！</span>
          </div>
          <el-pagination class="posegBox" style="margin-top: 20px"
            v-if="sumFieldPropConfigs && sumFieldPropConfigs.length" @size-change="handleSizeChanges"
            @current-change="handleCurrentChanges" background :current-page="sumPageObj.pageNum"
            :page-sizes="[20, 50, 100, 200]" :page-size="sumPageObj.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="sumPageObj.totalRecord">
          </el-pagination>
        </div>
        <div class="echarBox" v-if="drawerBottom">
          <div class="echbuttonBox">
            <el-button class="buttond" type="primary" icon="el-icon-download" circle @click="handleDownLoad"></el-button>
          </div>
          <div class="echarsSunBox">
            <div style="height: 100%; width: 100%" :id="momentId"></div>
          </div>
        </div>
      </div>
    </el-drawer>

  </div>
</template>
<script>
import * as echarts from "echarts";
import { getChartsList, getdateBaseChartsData, getCharData } from "@/api/moveCharts/moveCharts.js";
import { getChartTablepost, TableSum, exportEcharts, getFilter, getBatchsave } from "@/api/chart/index.js";
import { getLocal, setLocal, removeLocal } from "@/utils/methods";
import { getdataSetlist, getChartData, detailDeptree } from "@/api/dataSet/index.js";
import { getPermiss } from "@/api/bisystem/bisystem"
import clone from "clone";
import SelectHeader from '@/components/SelectHeader/index.vue';
import { chartView } from "@/views/chart/utils/chartView.js"

const char = new chartView()
export default {
  name: "echars",
  data() {
    return {
      that:this,
      // teamID:'',
      tabLoading: true,
      teamids: '',
      catName: '',
      // filterPropList: [],
      dataEchart: {},
      rowValue: null,
      tableSearce: {
        top: "",
        orderBy: "",
        isAsc: null,
      },
      sortFieldoptions: [],
      sortordoptions: [
        { label: "升序", value: true },
        { label: "降序", value: false },
        { label: "默认", value: null },
      ],
      eChartsData: [],
      biisabled: false,
      dataDrawer: false,
      dataSourceCondition: {},
      chartsType: "",
      chartsIndex: 0,
      chartsData: "",
      dataSourceOption: [],
      drawerBottom: false,
      fieldPropConfigs: [], //表格表头
      tableData: [],
      tableAsc: false,
      sortName: "",
      sumFieldPropConfigs: [],
      sumTableData: [],
      searchChartsData: null,
      searchChartsDataID: "",
      heightData: "1000",
      sumsadd: [], //主表合计
      sumsadds: [], //从表合计
      tableDataParam: { datasetId: '', pageNum: 1, pageSize: 20, totalRecord: 0, filterPropList: '', orderBy: '', asc: false },
      pageObj: { pageNum: 1, pageSize: 20, totalRecord: 0 },
      sumPageObj: { pageNum: 1, pageSize: 20, totalRecord: 0 },
      searchFormInformation: [],
      echartsSearchOption: {},
      lineHistogramForm: null,
      momentId: "",
      momentWidth: "",
      momentHeight: "",
      momentDataSetId: "",
      isSumData: false,
      base64: "",
      isgetBase64: false,

      inputModel: [],
      resetModule: [], // 第一次加载进来的 用来清空
      options: [
        { label: ">", value: "0" },
        { label: "<", value: "1" },
        { label: "=", value: "2" },
        { label: "!=", value: "3" },
        { label: ">=", value: "4" },
        { label: "<=", value: "5" },
      ],
      isAllChart: false,
      DSId: "",
      count: 0,
      allDataEchart: [],
      switchData: "1",
      //子表的变量
      subTableDataList: { id: this.datasetId, pageNum: 1, pageSize: 20, totalRecord: 0 },
      subTableName: [], //tabs标签页
      subTableHeader: [], //子表表头
      subTableData: [], //子表数据
      activeName: "", //子表ID
      subTableid: "", //主表ID
      ridObj: {},
      currentRowOfMainTable: null, //判断有没点击主表行
      mainTableIndex: {}, //主表下标为0的数据
      tabsIndex: 0, //标签页下标
      loading: true,
      remarks: [], //备注
      tabClickIndex: "",
      tabClickLabel: "",
      rowIndex: -1, //行索引
      columnIndex: -1, //列索引
      remarksShow: false, //判断显示隐藏保存按钮
      vloading: false,
      listFilterParamsVO: [],
    };
  },
  props: {
    // 当前的路由id
    datasetId: {

    },
    currantName:{},
    // dataEchart: {
    //   type: Object,
    //   default: {},
    // },


  },
  //监听
  watch: {
    // 图表详情弹框
    drawerBottom(val){
      if(!val){
        this.resetChartDetailParams()
      }
    },

    // 路由页面重新加载时出发 家在数据在这里
    datasetId(val) {
      // this.datasetId = val
      this.echartsId = ''
      this.heightList = ''
      this.catName = ''
      this.tableDataParam.id = val
      this.subTableDataList.id = val
      this.resetTableParams()
      this.resetSubTablePrams()
      this.subTableData = []
      this.tableData = []
      // this.filterPropList = []
      this.subTableName = []
      this.eChartsData = []
      removeLocal('teamID')
      removeLocal('heightList')
      removeLocal('catName')
      this.getChartSelect(false)
    },
    tableData: {
      handler() {
        this.$nextTick(() => {
          if (this.$refs.tableRef) this.$refs.tableRef.doLayout();
        });
      }, deep: true,
    },


    //选择对应图表中没有数据触发

    //选择对应图表中的下拉数据
    sendAllDataEchart: {
      handler: function (val) {
        this.allDataEchart = val;
      }, deep: true, immediate: true,
    },
  },
  computed: {

    //格式化搜索框里的数据
    filterPropList(){
      return this.inputModel.map(item => ({
        betweenValue: item.betweenValue?.length ?  item.betweenValue: null,
        compareType: item.compareType,
        datePrecision: item.datePrecision,
        filterType: item.filterType,
        fname: item.fname,
        id: item.id,
        value: item.value,
        filterDataValue:item.filterDataValue ? (item.filterDataValue instanceof Array ?item.filterDataValue : [item.filterDataValue ]) : null,
        addType:item.addType
      }))
    }
  },
  created() {
    this.resetAll()
    this.getChartSelect(false)
  },
  mounted() { },
  beforeCreate(){},
  filters:{
    decimalDigit(scope,item,that){
      if ((item.ftype == 1 && item.decimalDigit) ||(item.ftype == 3 && item.decimalDigit )) {
        scope =  that.toThousands(scope, Number(item.decimalDigit));
      }
      return scope
    }
  },
  methods: {

    // 清空全部搜索
    resetAll() {
      this.echartsId = getLocal("teamID") || ''
      this.heightList = getLocal("heightList") || ''
      this.catName = getLocal("catName") || ''
      this.resetTableParams()
      this.resetSubTablePrams()
      this.resetFilterPropList()

    },
    // 清空详情的查询条件

    resetChartDetailParams(){
     this.tableSearce= {   
        top: "",
        orderBy: "",
        isAsc: null,
      }
      this. sumPageObj= { pageNum: 1, pageSize: 20, totalRecord: 0 }
    },

    // 清空主表表格搜索条件
    resetTableParams() {
      this.tableDataParam = {
        id: this.datasetId,
        pageNum: 1,
        pageSize: 20,
        totalRecord: 0,
        orderBy: '',
        isAsc: false
      }
    },
    resetSubTablePrams() {
      this.subTableDataList = { id: this.datasetId, pageNum: 1, pageSize: 20, totalRecord: 0 }
    },
    // 清空筛选数组filter
    resetFilterPropList() {
      let resetFilds = ['value', 'compareType', 'datePrecision', 'dictId', 'filterDataValue']
      let filterDafault = {
          1:'filterDataValue',
          3:'betweenValue',
          "default":'value'
      }
      this.inputModel?.forEach((item) => {
        for (const i of resetFilds) {
          item[i] = null
        }
        const fileName = filterDafault[item.moduleType] || filterDafault['default'] 

        
      // 取今月第一天和今天的日期
        // if(item.moduleType==3 || item.filterType==2 ||  fileName=='betweenValue'){
        //   item.betweenValue = [this.$formate(new Date(),'{y}-{m}-01'), this.$formate(new Date(),'{y}-{m}-{d}')] 
        // }  

        // 无论是不是虚拟字段 只要有默认值
          if(item.filterValue || item.filterValue==0){  
           item[fileName ] == (fileName =='betweenValue') ? item.filterValue?.split('至') : item.filterValue 
          }

          // 如果没有默认值

      });
    },
    // 获取右上角选择对应图标下拉字段 一进来就触发就可以了
    getChartSelect(isLoadData = true) {
      let teamID = this.echartsId // 获取当前echartsId
      let dataEchart = this.dataEchart
      let heightList = this.heightList
      let catName = this.catName
      this.inputModel = []

      let selectChart = new Promise((resolve, reject) => {
        getChartData({ datasetId: this.datasetId }).then((res) => {
          if (res.length) {
            // 默认展示数据集第一条图表
            teamID = teamID || res[0].id
            heightList = heightList || res[0].canvasHeight
            catName = catName || res[0].catName
            this.allDataEchart = res;
            dataEchart = dataEchart || res[0];
            this.teamids = "";
          } else {
            // setLocal("datasetId",  this.datasetId);
            dataEchart = {};
            this.allDataEchart = [],
              this.teamids = this.datasetId;
          }
          this.echartsId = teamID;
          this.dataEchart = dataEchart || {}
          this.heightList = heightList || ''
          this.catName = catName || ''
          setLocal("datasetId", this.datasetId);
          setLocal("teamID", this.echartsId);
          if (heightList) setLocal("heightList", heightList);
          setLocal("catName", this.catName);
          resolve(res)
        });
      })
      // 获取当前顶部选项框数据
      let filterSelect = new Promise((resolve, roject) => {
        getFilter({ id: this.datasetId }).then((rel) => {
          this.inputModel = rel || []
          this.resetFilterPropList()
          resolve(rel)
        });
      })
      // 确保所有的请求已经完成
      Promise.all([selectChart, filterSelect]).then(res => {
        this.resetTableParams()
        this.resetSubTablePrams()
        if (isLoadData){
          this.handleSearch()
        }
      })
    },
    getFirstDay() {
      let date = new Date()
      const y = date.getFullYear(); // 获取年份
      let m = date.getMonth() + 1; // 获取月份
      const d = '01';
      m = m.toString().padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    getLastDay() {
      let date = new Date()
      const y = date.getFullYear(); // 获取年份
      let m = date.getMonth() + 1; // 获取月份
      let d = new Date(y, m, 0).getDate(); // 获取当月最后一日
      m = m.toString().padStart(2, '0')
      d = d.toString().padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    // 在输入框输入文字后使用回车键触发的事件
    handleEnter() {
      this.handleSearch();
    },
    // 行的 className 的回调方法，也可以使用字符串为所有行设置一个固定的 className。
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引加到行数据中
      row.index = rowIndex;
    },
    //单元格的 className 的回调方法，也可以使用字符串为所有单元格设置一个固定的 className。
    tableCellClassName({ column, columnIndex }) {
      //把每一列的索引加到列数据中
      column.index = columnIndex;
    },
    //当某个单元格被点击时会触发该事件
    cellClick(row, column, cell, event) {
      if (column.label == "备注") {
        this.rowIndex = row.index;
        this.columnIndex = column.index;
      }
    },
    //输入框失去焦点事件（初始化中间变量）
    inputblur(scope) {
      this.remarks.push(scope);
      this.rowIndex = -1;
      this.columnIndex = -1;
    },
    // 保存备注按钮事件
    saveNotes() {
      let ids = this.remarks.filter((v) => {
        return v.remark;
      });
      getBatchsave(null, ids).then((res) => {
        if (this.$jsons.data.code != 200) {
          this.$message.error(this.$t(this.$jsons.data.message));
        } else {
          this.$message.success("保存成功");
        }
      });
    },
    //当某一行被点击时会触发该事件
    openDetails(row) {
      this.currentRowOfMainTable = row;
      if (this.subTableName.length) this.handleClick({ index: this.tabsIndex });
    },
    //子表标签页事件
    handleClick(tab) {
      this.loading = true;

      this.subTableData = []
      this.tabsIndex = tab.index;
      this.activeName = this.subTableName[this.tabsIndex].id
      this.subTableDataList.id = this.activeName 
      let rid = this.subTableName[this.tabsIndex].fieldName
      this.subTableDataList.rid = rid
      this.subTableDataList.pageNum=1
      // alert(rid)
      // alert(this.currentRowOfMainTable[rid])
      this.subTableDataList.ridvalue = this.currentRowOfMainTable[rid]

      this.getSubTableList()
    },
    // 获取从表的数据 请求接口 传rid 和 ridvalue
    getSubTableList() {
      let list = this.filterPropList ? this.filterPropList : this.subTableHeader
      console.log('11111111111');
      getChartTablepost(this.subTableDataList, list).then((res) => {
        this.subTableData = res.result;
        let fieldPropConfigs = []
        res.fieldPropConfigs.filter(
            (item) => {
              if(item.isQuery){
                fieldPropConfigs.push(item)

              }
              // 过滤出类型为1或者3的数据
              // if ((item.ftype == 1 && item.decimalDigit != null) ||
              //   (item.ftype == 3 && item.decimalDigit != null)) {
              //   numberadd.push(item)
              // }
            }
          )
        this.subTableHeader =fieldPropConfigs;
        this.subTableDataList.totalRecord = res.totalRecord
        this.sumsadds = res.sums
        this.$forceUpdate();
        this.loading = false;

      }
      )
    },
    // 表格排序数列查询
    tableClick() {
      this.$nextTick(() => {
        this.chartsData = {
          ...this.chartsData,
          ...this.tableSearce,
        }
        this.getSumData();

        this.echartsList();
      });
    },
    //重置按钮
    resetDetail(){
      this.resetChartDetailParams()
      this.sumPageObj.id = this.momentDataSetId || this.datasetId
      this.tableClick()
    },
    // 图表表格排序
    echarsSortChange(column) {
      this.$nextTick(() => {
        const params = this.sortOrder(column)
        this.chartsData = {
          ...this.chartsData,
          ...params
        }
        this.getSumData();
        this.echartsList();
      });
    },

    // 主表排序,当表格的排序条件发生变化的时候会触发该事件
    sortChange(columns) {
      const params = this.sortOrder(columns)

      this.tableDataParam = {
        ...this.tableDataParam,
        ...params
      }
      this.getTableData(this.tableDataParam);
    },

    // 排序的字段处理
    sortOrder(column) {
      const { order, column: { property } } = column
      return {
        "ascending": { isAsc: true, orderBy: property },
        'descending': { isAsc: false, orderBy: property },
        '': { isAsc: '', orderBy: '' }
      }[order || '']
    },
    // 打印
    inputClick() {
      let dataQueryDTO = {
        datasetId: "",
        filters: [],
      };
      const menu = getLocal("catName");
    
      const fileName = (this.currantName ||  menu + "表格数据" )+ ".xlsx"; // 导出文件名

      dataQueryDTO.datasetId = this.tableDataParam.id;
      dataQueryDTO.filters = this.filterPropList
      this.exportCharts({dataQueryDTO,fileName})
    
    },
    handleDownLoad() {
      let dataQueryDTO = {
        chartSet: {},
        datasetId: "",
        filters: [],
        type: "",
      };
      dataQueryDTO.chartSet = this.chartsData.chartSet;
      dataQueryDTO.datasetId = this.tableDataParam.id;
      dataQueryDTO.type = this.chartsData.chartsType;
      // chartimg
      let index = this.chartsData.DataURL?.indexOf(",");
      let chartImgStr =this.chartsData.DataURL?.slice(index + 1);
      this.exportCharts({dataQueryDTO,chartImgStr,})
    },
// 导出打印接口
    exportCharts({chartImgStr,dataQueryDTO,fileName='monthAssess.xlsx'}){
      exportEcharts({ chartImgStr, dataQueryDTO }).then((res) => {
        const content = res.data;
        const blob = new Blob([content]); // 构造一个blob对象来处理数据
        // 对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
        // IE10以上支持blob但是依然不支持download
        if ("download" in document.createElement("a")) {
          // 支持a标签download的浏览器
          const link = document.createElement("a"); // 创建a标签
          link.download = fileName ||new Date().getTime()+'.xlsx'; // a标签添加属性
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click(); // 执行下载
          URL.revokeObjectURL(link.href); // 释放url
          document.body.removeChild(link); // 释放标签
        } else {
          // 其他浏览器
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },

    // 主表合计事件,自定义的合计计算方法
    getSummaries(param) {
      return this.totalEncapsulation(
        param,
        this.fieldPropConfigs,
        this.sumsadd
      );
    },
    // 子表合计事件
    getSummariess(param) {
      return this.totalEncapsulation(param, this.subTableHeader, this.sumsadds);
    },

    // 合计封装
    totalEncapsulation(params, headers, totals) {
      const { columns, data } = params;
      const sums = [];
      let isTotaladd = headers.filter(function (item) {
        return item.isTotal;
      });
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        for (let i = 0; i < isTotaladd.length; i++) {
          if (column.property === isTotaladd[i].fname) {
            sums[index] = this.toThousands(totals[column.property], 2);
          }
        }
      });
      return sums;
    },
    // 千分位转换事件
    toThousands(num, expressionDecimalDigit) {
      let newnum = "";
      if (num === "" || num === undefined || num === null) {
        newnum = "";
      } else {
        newnum = num + "";
      }
      if (expressionDecimalDigit && expressionDecimalDigit !== 0) {
        let [sInt, sFloat] = (Number.isInteger(newnum)
          ? `${newnum}`
          : Number(newnum)?.toFixed(expressionDecimalDigit) + ""
        ).split(".");
        sInt = sInt.replace(/\d(?=(\d{3})+$)/g, "$&,");
        return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
      } else {
        let [sInt, sFloat] = (Number.isInteger(newnum)
          ? `${newnum}`
          : newnum
        ).split(".");
        sInt = sInt.replace(/\d(?=(\d{3})+$)/g, "$&,");
        return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
      }
    },
    getEcharts() {
      this.eChartsData = []
      getChartsList({ catId: this.echartsId }).then((res) => {
        let data = [];
        for (let i = 0; i < res.length; i++) {
          res[i].chartSet.dataset = res[i].datasetId;
          res[i].data = null

          let parameters = {
            ...res[i],
            filters: this.filterPropList,
            type: res[i].chartsType,
          };
          parameters.chartSet = res[i].chartSet;
          parameters.datasetId = res[i].datasetId;
          parameters.type = res[i].chartsType;
          delete parameters.delFlag

          data.push(parameters);
        }
        this.eChartsData = data;
        this.subTableid = res[0].datasetId;

        getdateBaseChartsData(data, res[0].datasetId)
          .then((res) => {
            this.eChartsData.map((item, index) => {
              item.data = res[index]
              item.viewType = 'view'
              char.initOptions(item, null)
            })

          })
          .catch((res) => {

            this.eChartsData.map((item, index) => {
              item.data = null
              char.initOptions(item, null)
            })

          });

      });
    },
    getTableData(listFilterParamsVO) {
      this.vloading = true;
      // 主表数据
      this.tableData = []
      this.subTableData = []
      let objectData = {
        list: this.filterPropList
      }
      if (listFilterParamsVO && listFilterParamsVO.length > 0) {
        objectData['filterList'] = listFilterParamsVO
      }
      getChartTablepost(this.tableDataParam, objectData).then(async (res) => {
        console.log("BI主表数据", res);
        this.vloading = false;
        if (res.result[0] != undefined)
          this.currentRowOfMainTable = this.currentRowOfMainTable ? this.currentRowOfMainTable : { ...res.result[0] }
        this.sumsadd = res.sums;
        // isWhereField 是否显示
        let fieldPropConfigs = []
        let remarksShow = false
        let numberadd = []
        res.fieldPropConfigs.filter(
          (item) => {
            if(item.isQuery){
              fieldPropConfigs.push(item)

            }
            if (item.showName=='备注') remarksShow = true
            // 过滤出类型为1或者3的数据
            // if ((item.ftype == 1 && item.decimalDigit != null) ||
            //   (item.ftype == 3 && item.decimalDigit != null)) {
            //   numberadd.push(item)
            // }
          }
        )
        
        this.fieldPropConfigs = fieldPropConfigs
        this.remarksShow = remarksShow
        let newSubSums = subSumsFunction(res.subSums, res.result);
        this.tableData = newSubSums
          this.tableDataParam.totalRecord = res.totalRecord;
        if (this.tableData.length) {
          this.secondaryDataSet()
        }
      }).catch((_) => (this.vloading = false));
      function subSumsFunction(subSums, result) {
        if (subSums.length == 0) {
          return result;
        } else {
          let add = [];
          for (let i = 0; i < subSums.length; i++) {
            add[i] = [];
            let subTotalGrouplength = 0;
            for (let j in subSums[i].subTotalGroup) {
              subTotalGrouplength++;
            }
            for (let k = 0; k < result.length; k++) {
              let subTotalGroupcount = 0;
              for (let j in subSums[i].subTotalGroup) {
                if (result[k][j] === subSums[i].subTotalGroup[j]) {
                  subTotalGroupcount++;
                }
                if (subTotalGroupcount === subTotalGrouplength) {
                  add[i].push(result[k]);
                }
              }
            }
            let data = subSums[i].subTotal;
            data.backgSpan = true;
            add[i].push(data);
          }
          let arr = add.reduce(function (a, b) {
            return a.concat(b);
          });
          return arr;
        }
      }
    },
    // 表格和图表间切换触发的事件
    handleSwitchChange(e) {
      this.$nextTick(() => {
        this.handleSearch();
      })
      if (getLocal("heightList")) {
        let heightListed = getLocal("heightList");
        this.$nextTick(() => {
          this.heightData = heightListed + "px";
        });
      } else {
        this.$nextTick(() => {
          this.heightData = "100%";
        });
      }
    },
    /* 下了选择同一个数据集的不同图表 */
    selectChart(item) {
      // 只改变条件参数 不请求接口
      this.echartsId = item.id
      setLocal("teamID", item.id);
      setLocal("heightList", item.canvasHeight);
      this.dataEchart = item;
      if (this.switchData == 2) {
        // 如果当前在图表页面 调接口
        this.handleSearch()
      }
    },

    chartsDataProcessing(item, chartsOption) {
      let option;
      if (item.chartsType == "histogram") {
        let data1 = [];
        let data2 = [];
        if (chartsOption && chartsOption?.length) {
          for (let i = 0; i < chartsOption?.length; i++) {
            data1.push(chartsOption[i].name);
            data2.push(chartsOption[i].value);
          }
        } else {
          data1 = ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"];
          data2 = [5, 20, 36, 10, 10, 20];
        }
        option = {
          title: {
            text: item.webConfig.title,
          },
          tooltip: {},
          legend: {
            top: "7%",
          },
          xAxis: {
            data: data1,
            axisLabel: this.xAxisAxisLabel,
          },
          yAxis: {
            axisLabel: this.yAxisAxisLabel,
          },
          series: [
            {
              type: "bar",
              data: data2,
            },
          ],
        };
      } else if (item.chartsType == "charDSJZ") {
        let typedata = [];
        if (chartsOption && chartsOption?.dimensions?.length) {
          let newdata = clone(chartsOption.dimensions);
          for (let i = 0; i < newdata.length - 1; i++) {
            let data = {
              type: "bar",
            };
            typedata.push(data);
          }
        } else {
          typedata = [{ type: "bar" }, { type: "bar" }, { type: "bar" }];
        }
        option = {
          title: {
            text: item.webConfig.title,
          },
          legend: {
            top: "7%",
          },
          tooltip: {},
          dataset: {
            dimensions:
              chartsOption && chartsOption.dimensions
                ? chartsOption.dimensions
                : ["product", "2015", "2016", "2017"],
            source:
              chartsOption && chartsOption.source
                ? chartsOption.source
                : [
                  {
                    product: "Matcha Latte",
                    2015: 43.3,
                    2016: 85.8,
                    2017: 93.7,
                  },
                  { product: "Milk Tea", 2015: 83.1, 2016: 73.4, 2017: 55.1 },
                  {
                    product: "Cheese Cocoa",
                    2015: 86.4,
                    2016: 65.2,
                    2017: 82.5,
                  },
                  {
                    product: "Walnut Brownie",
                    2015: 72.4,
                    2016: 53.9,
                    2017: 39.1,
                  },
                ],
          },
          xAxis: {
            type: "category",
            axisLabel: this.xAxisAxisLabel,
          },
          yAxis: {
            axisLabel: this.yAxisAxisLabel,
          },
          // Declare several bar series, each will be mapped
          // to a column of dataset.source by default.
          series: typedata,
        };
      } else if (item.chartsType == "contrastDSJZ") {
        let typedata = [];
        if (chartsOption && chartsOption?.dimensions?.length) {
          let newdata = clone(chartsOption.dimensions);
          for (let i = 0; i < newdata.length - 1; i++) {
            let data = {
              type: "bar",
            };
            typedata.push(data);
          }
        } else {
          typedata = [{ type: "bar" }, { type: "bar" }, { type: "bar" }];
        }
        option = {
          title: {
            text: item.webConfig.title,
          },
          legend: {
            top: "7%",
          },
          tooltip: {},
          dataset: {
            dimensions:
              chartsOption && chartsOption.dimensions
                ? chartsOption.dimensions
                : ["product", "2015", "2016", "2017"],
            source:
              chartsOption && chartsOption.source
                ? chartsOption.source
                : [
                  {
                    product: "Matcha Latte",
                    2015: 43.3,
                    2016: 85.8,
                    2017: 93.7,
                  },
                  { product: "Milk Tea", 2015: 83.1, 2016: 73.4, 2017: 55.1 },
                  {
                    product: "Cheese Cocoa",
                    2015: 86.4,
                    2016: 65.2,
                    2017: 82.5,
                  },
                  {
                    product: "Walnut Brownie",
                    2015: 72.4,
                    2016: 53.9,
                    2017: 39.1,
                  },
                ],
          },
          xAxis: {
            type: "category",
            axisLabel: this.xAxisAxisLabel,
          },
          yAxis: {
            axisLabel: this.yAxisAxisLabel,
          },
          // Declare several bar series, each will be mapped
          // to a column of dataset.source by default.
          series: typedata,
        };
      } else if (item.chartsType == "lineChart") {
        let data1 = [];
        let data2 = [];
        if (chartsOption && chartsOption?.length) {
          for (let i = 0; i < chartsOption.length; i++) {
            data1.push(chartsOption[i].name);
            data2.push(chartsOption[i].value);
          }
        } else {
          data1 = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
          data2 = [150, 230, 224, 218, 135, 147, 260];
        }
        option = {
          title: {
            text: item.webConfig.title,
          },
          legend: {
            top: "7%",
          },
          xAxis: {
            type: "category",
            data: data1,
            axisLabel: this.xAxisAxisLabel,
          },
          yAxis: {
            type: "value",
            axisLabel: this.yAxisAxisLabel,
          },
          series: [
            {
              data: data2,
              type: "line",
            },
          ],
        };
      } else if (item.chartsType == "lineHistogram") {
        let series = [];
        if (chartsOption && chartsOption?.cols?.length) {
          series = chartsOption.cols;
        } else {
          series = [
            {
              name: "Evaporation",
              type: "bar",
              data: [
                2.0,
                4.9,
                7.0,
                23.2,
                25.6,
                76.7,
                135.6,
                162.2,
                32.6,
                20.0,
                6.4,
                3.3,
              ],
            },
            {
              name: "Precipitation",
              type: "bar",
              data: [
                2.6,
                5.9,
                9.0,
                26.4,
                28.7,
                70.7,
                175.6,
                182.2,
                48.7,
                18.8,
                6.0,
                2.3,
              ],
            },
            {
              name: "Temperature",
              type: "line",
              yAxisIndex: 1,
              data: [
                2.0,
                2.2,
                3.3,
                4.5,
                6.3,
                10.2,
                20.3,
                23.4,
                23.0,
                16.5,
                12.0,
                6.2,
              ],
            },
          ];
        }
        let rows = [];
        if (chartsOption && chartsOption?.rows?.length) {
          rows = chartsOption.rows;
        } else {
          rows = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        }
        let legend = [];
        let yAxis = [];
        if (chartsOption && chartsOption?.cols?.length) {
          legend = chartsOption.cols.map((item) => item.name);
          yAxis = chartsOption.cols.map((item) => {
            let obj = {
              type: "value",
              name: item.name,
              axisLabel: this.yAxisAxisLabel,
            };
            return obj;
          });
        } else {
          legend = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
          yAxis = [
            {
              type: "value",
              name: "Precipitation",
              min: 0,
              max: 250,
              interval: 50,
              axisLabel: { formatter: "{value} ml" },
            },
            {
              type: "value",
              name: "Temperature",
              min: 0,
              max: 25,
              interval: 5,
              axisLabel: { formatter: "{value} °C" },
            },
          ];
        }
        option = {
          title: {
            text: item.webConfig.title,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: { type: "cross", crossStyle: { color: "#999" } },
          },
          legend: { data: legend, top: "7%" },
          xAxis: [
            {
              type: "category",
              data: rows,
              axisPointer: { type: "shadow" },
              axisLabel: this.xAxisAxisLabel,
            },
          ],
          yAxis: yAxis,
          series: series,
        };
      } else if (item.chartsType == "charbB") {
        let dataList = [];
        if (chartsOption && chartsOption?.length) {
          for (let i = 0; i < chartsOption.length; i++) {
            let newdata = {
              value: chartsOption[i].value,
              name: chartsOption[i].name,
            };
            dataList.push(newdata);
          }
        } else {
          dataList = [
            { value: 1048, name: "Search Engine" },
            { value: 735, name: "Direct" },
            { value: 580, name: "Email" },
            { value: 484, name: "Union Ads" },
            { value: 300, name: "Video Ads" },
          ];
        }
        option = {
          title: { text: item.webConfig.title },
          tooltip: { trigger: "item" },
          legend: {
            type: "scroll",
            orient: "vertical",
            top: 20,
            bottom: 20,
            right: 10,
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: "80%",
              center: ["40%", "50%"],
              data: dataList,
            },
          ],
        };
      } else if (item.chartsType == "linkageShare") {
        let newdata = [];
        if (chartsOption && chartsOption?.source?.length) {
          for (let i = 0; i < chartsOption.source.length - 1; i++) {
            let datalist = {
              type: "line",
              smooth: true,
              seriesLayoutBy: "row",
              emphasis: { focus: "series" },
            };
            newdata.push(datalist);
          }
          let linedata = {
            type: "pie",
            id: "pie",
            radius: "50%",
            center: ["45%", "25%"],
            emphasis: {
              focus: "self",
            },
            label: {
              formatter: "{b}: {@" + chartsOption.source[0][1] + "} ({d}%)",
            },
            encode: {
              itemName: chartsOption.source[0][0],
              value: chartsOption.source[0][1],
              tooltip: chartsOption.source[0][1],
            },
          };
          newdata.push(linedata);
        } else {
          newdata = [
            {
              type: "line",
              smooth: true,
              seriesLayoutBy: "row",
              emphasis: { focus: "series" },
            },
            {
              type: "line",
              smooth: true,
              seriesLayoutBy: "row",
              emphasis: { focus: "series" },
            },
            {
              type: "line",
              smooth: true,
              seriesLayoutBy: "row",
              emphasis: { focus: "series" },
            },
            {
              type: "line",
              smooth: true,
              seriesLayoutBy: "row",
              emphasis: { focus: "series" },
            },
            {
              type: "pie",
              id: "pie",
              radius: "50%",
              center: ["50%", "25%"],
              emphasis: { focus: "self" },
              label: { formatter: "{b}: {@2012} ({d}%)" },
              encode: { itemName: "product", value: "2012", tooltip: "2012" },
            },
          ];
        }
        option = {
          title: { text: item.webConfig.title },
          legend: { top: "7%" },
          tooltip: { trigger: "axis", showContent: false },
          dataset: {
            source:
              chartsOption && chartsOption.source
                ? chartsOption.source
                : [
                  ["product", "2012", "2013", "2014", "2015", "2016", "2017"],
                  ["Milk Tea", 56.5, 82.1, 88.7, 70.1, 53.4, 85.1],
                  ["Matcha Latte", 51.1, 51.4, 55.1, 53.3, 73.8, 68.7],
                  ["Cheese Cocoa", 40.1, 62.2, 69.5, 36.4, 45.2, 32.5],
                  ["Walnut Brownie", 25.2, 37.1, 41.2, 18, 33.9, 49.1],
                ],
          },
          xAxis: { type: "category", axisLabel: this.xAxisAxisLabel },
          yAxis: { gridIndex: 0, axisLabel: this.yAxisAxisLabel },
          grid: { top: "55%" },
          series: newdata,
          legend: {
            type: "scroll",
            orient: "vertical",
            top: 20,
            bottom: 20,
            right: 10,
          },
        };
      }
      return option;
    },
    dataSelectChange(form) {
      if (Array.isArray(form?.bar) && Array.isArray(form?.line)) {
        this.lineHistogramForm = form;
      }
    },
    iconClick(item, idnex) {
      this.echartsSearchOption = clone(item);
      this.chartsIndex = clone(idnex);
      this.dataSourceCondition = clone(item.chartSet);
      this.searchFormInformation = clone(item.filterPropList);
      if (item.chartsType == "histogram") {
        this.chartsType = "histogram";
      } else if (item.chartsType == "charDSJZ") {
        this.chartsType = "charDSJZ";
      } else if (item.chartsType == "contrastDSJZ") {
        this.chartsType = "contrastDSJZ";
      } else if (item.chartsType == "lineChart") {
        this.chartsType = "lineChart";
      } else if (item.chartsType == "lineHistogram") {
        this.chartsType = "lineHistogram";
      } else if (item.chartsType == "charbB") {
        this.chartsType = "charbB";
      } else if (item.chartsType == "linkageShare") {
        this.chartsType = "linkageShare";
      }
      this.dataDrawer = true;
    },
    dataDrawerhandleClose() {
      this.dataDrawer = false;
    },
    chartsRender(boxId, boxOption, type) {
      var chartDom = document.getElementById(boxId);
      if (chartDom) {
        var myChart = echarts.init(chartDom);
        var option = boxOption;
        if (type == "linkageShare") {
          setTimeout(() => {
            myChart.on("updateAxisPointer", function (event) {
              const xAxisInfo = event.axesInfo[0];
              if (xAxisInfo) {
                const dimension = xAxisInfo.value + 1;
                myChart.setOption({
                  series: {
                    id: "pie",
                    label: {
                      formatter: "{b}: {@[" + dimension + "]} ({d}%)",
                    },
                    encode: {
                      value: dimension,
                      tooltip: dimension,
                    },
                  },
                });
              }
            });
            if (this.isgetBase64) {
              setTimeout(() => {
                this.base64 = myChart.getDataURL({
                  pixelRatio: 2,
                  type: "png",
                  backgroundColor: "#fff",
                });
              }, 1000);
              this.isgetBase64 = false;
            }
            myChart.setOption(option);
          });
        } else {
          myChart.hideLoading();
          if (this.isgetBase64) {
            setTimeout(() => {
              this.base64 = myChart.getDataURL({
                pixelRatio: 2,
                type: "png",
                backgroundColor: "#fff",
              });
            }, 1000);
            this.isgetBase64 = false;
          }
          myChart.setOption(option, true);
        }
        // let listener = function () {
        //     myChart.resize()
        // };
        // EleResize.on(chartDom, listener)
      }
    },
    // 一页大小
    handleSizeChange(val) {
      this.tableDataParam.pageSize = val;
      this.getTableData(this.tableDataParam);
    },
    // 点击页码
    handleCurrentChange(val) {
      this.tableDataParam.pageNum = val;
      this.getTableData(this.tableDataParam);
    },
    // 合计一页大小
    handleSizeChanges(val) {
      this.sumPageObj.pageSize = val;
      this.getSumData();
    },
    // 合计页码
    handleCurrentChanges(val) {
      this.sumPageObj.pageNum = val;
      this.getSumData();
    },
    // 合计一页大小
    handleSizeChangess(val) {
      this.subTableDataList.pageSize = val;
      // this.secondaryDataSet(this.subTableid);
      this.getSubTableList()
    },
    // 合计页码
    handleCurrentChangess(val) {
      this.subTableDataList.pageNum = val;
      // this.secondaryDataSet(this.subTableid);
      this.getSubTableList()
    },
    // 获取单独图表数据
    echartsList() {
      let echartadd = [];
      let echaData = {
        chartSet: this.chartsData.chartSet,
        datasetId: this.chartsData.datasetId || this.datasetId,
        filters:  this.chartsData.filters,
        type: this.chartsData.chartsType,
      };
      let echaId = this.chartsData.datasetId || this.datasetId;

      echartadd.push(echaData);
      getdateBaseChartsData(echartadd, echaId).then( async (res) => {
        this.drawerBottom = true;
        this.chartsData.data = res[0]
      this.chartsData = await  char.initOptions(this.chartsData, null,true)
        // this.getChartTableFunction(item);
      });
    },
    // 点击详情
    chartsDBclick(item) {
      this.chartsData = clone(item);
      this.chartsData.webConfig.chartsId = this.chartsData.webConfig.chartsId + 's'
      this.momentId = this.chartsData.webConfig.chartsId;
      this.momentDataSetId = this.chartsData.datasetId;
      this.sumPageObj.id = this.momentDataSetId || this.datasetId
      //444
      char.isgetBase64 = true;
      this.echartsList();
      this.chartsData = {
        ...this.chartsData,
        ...this.tableSearce
      }

      this.getSumData();

      return

    },
    // 条件搜索
    searchClick(data) {
      delete this.echartsSearchOption.chartSet.dataset;
      let chartsSearch = {
        chartSet: this.echartsSearchOption.chartSet,
        datasetId: this.echartsSearchOption.datasetId,
        filters: data,
        type: this.echartsSearchOption.chartsType,
      };
      getCharData(chartsSearch).then((res) => {
        this.eChartsData[this.chartsIndex].options = this.chartsDataProcessing(
          this.eChartsData[this.chartsIndex],
          res
        );
        this.$nextTick(() => {
          this.chartsRender(
            this.eChartsData[this.chartsIndex].webConfig.chartsId,
            this.eChartsData[this.chartsIndex].options
          );
        });
        // this.eChartsData[this.chartsIndex].
      });
    },
    // 数据源搜索
    echartsSearchClick(data) {
      let newdata = clone(data);
      // 如果包含bars和lines都是数组就是折线和柱状图
      if (Array.isArray(newdata?.bars) && Array.isArray(newdata?.lines)) {
        if (this.lineHistogramForm != null) {
          newdata = this.lineHistogramForm;
        } else {
          newdata = this.dataSourceCondition;
        }
      }
      let chartsData = clone(this.echartsSearchOption);
      let chartsDataID = newdata.dataset;
      delete newdata.dataset;
      let chartsSearch = {
        chartSet: newdata,
        datasetId: chartsDataID,
        filters: chartsData.filterPropList,
        type: chartsData.chartsType,
      };
      this.searchChartsData = newdata;
      this.searchChartsDataID = chartsDataID;
      getCharData(chartsSearch).then((res) => {
        this.eChartsData[this.chartsIndex].options = this.chartsDataProcessing(
          this.eChartsData[this.chartsIndex],
          res
        );
        this.$nextTick(() => {
          this.chartsRender(
            this.eChartsData[this.chartsIndex].webConfig.chartsId,
            this.eChartsData[this.chartsIndex].options
          );
        });
      });
    },
    getSumData() {
      this.sumTableData = [];
      let params = {
        ...this.chartsData,
        type: this.chartsData.chartsType
      }
 
      TableSum(this.sumPageObj, params).then((res) => {
        if (res.result.length) {
          this.sumPageObj.totalRecord = res.totalRecord,
            this.sumTableData = res.result;
        
            // this.sumFieldPropConfigs = [];
          // for (let i in res.result[0]) {
          //   for (let j = 0; j < res.fieldPropConfigs.length; j++) {
          //     if (i == res.fieldPropConfigs[j].fname) {
          //       this.sumFieldPropConfigs.push(res.fieldPropConfigs[j]);
          //       continue;
          //     }
          //   }
          // }

        const maps =  res.result.map(item=>(Object.keys(item)).length)
        this.sumFieldPropConfigs =  res.fieldPropConfigs.filter(item=>Object.keys(res.result[maps.findIndex(it=>it===Math.max(...maps))])?.includes(item.fname) )
    
          this.sortFieldoptions = [];
          for (let i = 0; i < this.sumFieldPropConfigs.length; i++) {
            let data = {
              label: this.sumFieldPropConfigs[i].fname,
              value: this.sumFieldPropConfigs[i].fname,
            };
            this.sortFieldoptions.push(data);
          }
        }
        setTimeout(() => {
          this.$refs.echarsTable?.doLayout();
        });
      });
      // }
    },
  
    // 重置按钮事件
    processReset() {
      this.resetAll()
      this.handleSearch()
    },
    // 点击查找按钮触发查询
    handleSearch() {
      this.getCurrentSelectList()
    },
    // 获取当前模块的数据 1表格 2图表
    getCurrentSelectList() {
      if (this.switchData == 2) {
        this.getEcharts(this.teamID, false, this.filterPropList);
      } else {
        this.getTableData(this.tableDataParam);
      }
    },

    //展示页面,子表数据
    secondaryDataSet(val) {
      detailDeptree({ id: val || this.tableData.datasetId || this.datasetId }).then((res) => {
        this.subTableName = res?.children  || [];
        if (this.subTableName.length > 0) {
          if (this.subTableName.length) {
            this.subTableName.forEach((t) => {
              let str = t?.referCondition?.split('@')[1] || t?.referCondition || '测试tab'
              // 直接用新字段复制str
              t.fieldName = str

            });

            this.activeName = this.subTableName[this.tabsIndex].id;
          }
          this.handleClick({ index: this.tabsIndex });
        }
      });
    },

     // 渲染**的tableheader
    renderSpecNameHeader(createElement, { column, $index }, item) {
      const self = this
      // 该列的绑定数据
      // console.log(column.label);
      // 列号
      // console.log($index);
      return createElement(
        'div',
        { style: 'display:inline-flex;' },
        [
          createElement('div', { domProps: { innerHTML: column.label } }),
          createElement(SelectHeader, {
            style: 'cursor: pointer;',
            // 组件 prop
            props: {
              fname: column.property,
              options: self.specIdOptions, // 下拉框选项
              defaultValue: self.examinerFieldChname, // 默认值
              dataType: item.ftype, // 数据类型
              filterType: item.filterType, // 过滤类型
              defaultProps: {
                value: 'examinerFieldName',
                label: 'examinerFieldChname'
              }
            },
            // 事件监听器在 `on` 属性内，
            // 但不再支持如 `v-on:keyup.enter` 这样的修饰器。
            // 需要在处理函数中手动检查 keyCode。
            on: {
              selectChange: self.selectChange,
              resetChange: self.resetChange
              // click: this.clickHandler
            },
            // 仅用于组件，用于监听原生事件，而不是组件内部使用
            // `vm.$emit` 触发的事件。
            nativeOn: {
              // click: this.nativeClickHandler
            }
          })
        ]
      )
    },
    // 选择框回调
    selectChange(data) {
      if (data.dataType == '2') {
        data.dateTimeValue = data.value.map(item => this.getTime(item));
        delete data.value;
      }
      const existingItem = this.listFilterParamsVO.find(item => item.fname === data.fname);
      if (existingItem) {
        existingItem.value = data.value;
        existingItem.dateTimeValue = data.dateTimeValue
      } else this.listFilterParamsVO.push(data);
      this.getTableData(this.listFilterParamsVO);
    },
    // 重置回调
    resetChange (data) {
      this.listFilterParamsVO = this.listFilterParamsVO.filter(item => item.fname !== data.fname);
      if (this.listFilterParamsVO.length > 0) {
        this.getTableData(this.listFilterParamsVO);
      } else this.getTableData();
    },
    getTime(data) {
      let date = new Date(data);
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份需要加1，然后补零
      let day = date.getDate().toString().padStart(2, '0');
      let formattedDate = `${year}-${month}-${day}`;
      return formattedDate
    },
  },
  components: {},
};
</script>
<style scoped lang="less">
::-webkit-scrollbar {
  display: none;
}
.echarss {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: white;
}

.echars {
  width: 100%;
  flex: 1;
  // height: calc(100% - 40px);
  // height: calc(93% - 60px);
  // height: calc(100% - 60px);
  background: white;
  position: relative;
}

.header-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}

.filter-left {
  display: flex;
  flex-wrap: wrap;

}

.filter-right {
  display: flex;
  align-items: center;
}


.echarsBoxd {
  width: 100%;
  background: white;
}

.chartsBox {
  position: absolute;
  border: 1px solid rgb(236, 236, 236);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .searnBox {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 2px;
    // padding: 20px 50px;
    box-sizing: border-box;
  }

  .chartsSunBox {
    flex: 1;
  }
}
/deep/ .el-tabs--border-card{
  display: flex;
  flex-direction: column;
}
/deep/ .el-tabs__content{
  flex: 1;
}

/deep/ .el-textarea .el-textarea__inner {
  border: 0;
  resize: none;
  height: 23px;
  width: 100%;
  padding: 5px 0;
}

/deep/ .el-table .cell {
  line-height: 28px;
}

.nodata {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 200;
}
/deep/  .el-table__body-wrapper{
  z-index: 2;
}

.backgSpanvalue {
  color:red;
  background: rgb(192, 192, 192);
  display: block;
  width: 100%;
  height: 100%;
}

.buttonBox {
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;

  padding: 0 20px;
  box-sizing: border-box;
}

.echarsBox {
  width: 100%;
  display: flex;
  height: 95%;

  .echBox {
    // float: left;
    // width: 600px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .echarBox {
    // float: left;
    // width: calc(100% - 600px);
    flex: 1;
    height: 100%;

    .echbuttonBox {
      width: 100%;
      height: 60px;
      padding-left: 20px;
      box-sizing: border-box;
    }

    .echarsSunBox {
      width: 100%;
      height: calc(100% - 70px);
    }
  }
}

.posegBox {
  width: 100%;
  height: 60px;
}

.tablespanBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.searceBox {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .inpiutBox {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;

    span {
      display: block;
      width: 80px;
      padding-left: 5px;
      box-sizing: border-box;
    }
  }

  .buttonBox {
    width: 60px;
  }
}
</style>
